import { colors } from "../styles/colors";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
// import moment from "moment";

export function getBreadcrumbs(location) {
  if (
    location.pathname === "/super-admin" ||
    location.pathname === "/vra" ||
    location.pathname === "/dra" ||
    location.pathname === "/aa" ||
    location.pathname === "/qa" ||
    location.pathname === "/customer"
  ) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname === "/super-admin/manage-users") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/super-admin",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Manage Users",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname === "/super-admin/reminders") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/super-admin",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Reminders",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname === "/super-admin/manage-users/add-edit-user/new") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/super-admin",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Manage Users",
        path: "/super-admin/manage-users",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Add New User",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/super-admin/manage-users/add-edit-user/")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/super-admin",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Manage Users",
        path: "/super-admin/manage-users",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Edit User",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/super-admin/profile/")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/super-admin",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Profile",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/vra/profile/")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/vra",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Profile",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/dra/profile/")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/dra",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Profile",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/aa/profile/")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/aa",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Profile",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/qa/profile/")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/qa",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Profile",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/customer/profile/")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/customer",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Profile",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname === "/add-edit-property/new") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/super-admin",
        color: "#A4A5A8"
      },
      {
        id: 1,
        name: "Add Property",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/add-edit-property/")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/super-admin",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Edit Property",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/super-admin/property/")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/super-admin",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Property Details",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/vra/property/")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/vra",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Property Details",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/dra/property/")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/dra",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Property Details",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/aa/property/")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/aa",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Property Details",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/qa/property/")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/qa",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Property Details",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/customer/property/")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/customer",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Property Details",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname === "/terms-and-conditions") {
    return [
      {
        id: 1,
        name: "Terms And Conditions",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  return [];
}

export const isJwtTokenValid = () => {
  const token = sessionStorage.getItem("token") || "";
  let hasExpired = true;
  let isVerified = false;
  let roles = [];
  let lastLogin = null;
  let username = "";

  if (Boolean(token)) {
    const decoded: any = jwt_decode(token);
    if (Date.now() < decoded.exp * 1000) {
      hasExpired = false;
      if (Boolean(decoded.verified)) {
        isVerified = true;
      }
      if (Boolean(decoded.roles)) {
        roles = decoded.roles;
      }

      if (Boolean(decoded.lastLogin)) {
        lastLogin = decoded.lastLogin;
      }
      if (Boolean(decoded.username)) {
        username = decoded.username;
      }
    }
  }

  return { hasExpired, isVerified, roles, lastLogin, username };
};

export const getUserRoles = () => {
  const token = sessionStorage.getItem("token") || "";
  let roles = [];

  if (Boolean(token)) {
    const decoded: any = jwt_decode(token);
    if (Date.now() < decoded.exp * 1000) {
      if (Boolean(decoded.roles)) {
        roles = decoded.roles;
      }
    }
  }

  return { roles };
};

export const clearSession = () => {
  ["token", "me"].forEach((k) => sessionStorage.removeItem(k));
};

export const isTokenPresent = () => {
  return Boolean(sessionStorage.getItem("token"));
};

export const getDefaultRoute = () => {
  const { hasExpired, roles } = isJwtTokenValid();
  let me: any = {};
  if (Boolean(sessionStorage.getItem("me"))) {
    const temp = sessionStorage.getItem("me");
    me = temp ? JSON.parse(temp) : "";
  }
  if (!hasExpired) {
    if (roles[0] === "super-admin") {
      return "/super-admin";
    }
    if (roles[0] === "vra") {
      return "/vra";
    }
    if (roles[0] === "dra") {
      return "/dra";
    }
    if (roles[0] === "aa") {
      return "/aa";
    }
    if (roles[0] === "qa") {
      return "/qa";
    }
  }
  return "/";
};

export function getNavbarSelected(location, item) {
  if (location.pathname === "/super-admin" && item.id === 1) {
    return true;
  }
  if (location.pathname === "/super-admin/reminders" && item.id === 2) {
    return true;
  }
  if (location.pathname === "/super-admin/manage-users" && item.id === 3) {
    return true;
  }
  return false;
}

// export const getPrettyDate = (d) => {
//   let dMoment = moment(d);
//   return dMoment.format("DD MMM YYYY hh:mm A");
// };

// export const getMonthAndYear = (d) => {
//   let dMoment = moment(d);
//   return dMoment.format("MMM YYYY");
// };

export const downloadCSV = (csvContent, filename) => {
  var hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
  hiddenElement.target = "_blank";
  hiddenElement.download = filename;
  hiddenElement.click();
};

export const downloadExcel = (excelContent, filename) => {
  var hiddenElement = document.createElement("a");
  hiddenElement.href =
    "data:application/vnd.ms-excel;base64," + encodeURI(excelContent);
  hiddenElement.target = "_blank";
  hiddenElement.download = filename;
  hiddenElement.click();
};

export const downloadAny = (fileContent, filename, fileType) => {
  var hiddenElement = document.createElement("a");
  hiddenElement.href = "data:" + fileType + ";base64," + encodeURI(fileContent);
  hiddenElement.target = "_blank";
  hiddenElement.download = filename;
  hiddenElement.click();
};

export const capitalize = function (word) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
};

export const getLoggedInUserData = () => {
  let meJSON = sessionStorage.getItem("me");
  return meJSON ? JSON.parse(meJSON) : null;
};

export const getDateInFormat = (date, format) => {
  return dayjs(date).format(format);
};

export const maskMobile = (mobile: string) => {
  return mobile && mobile.length === 10
    ? mobile.substring(0, 2) + "XXXXX" + mobile.substring(8)
    : mobile;
};

export const getUserInfo = () => {
  const me = sessionStorage.getItem("me") || "{}";
  const parsedMe = JSON.parse(me);
  const { firstName, lastName, organization, college, id } = parsedMe;

  return {
    firstName: firstName || "",
    lastName: lastName || "",
    organization: organization?.name || college?.name || "",
    id: id || ""
  };
};

export const formDateFromStringYYYYMMDD = (dateString) => {
  const dateParts = dateString.split("-");
  const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
  return date;
};

// export const getFromNowDate = (date) => {
//   var relativeTime = require("dayjs/plugin/relativeTime");
//   dayjs.extend(relativeTime);
//   return dayjs(date).fromNow();
// };
