/**
 * Asynchronously loads the component for ConfirmMail
 */

import { lazyLoad } from "utils/loadable";

export const ConfirmMail = lazyLoad(
  () => import("./index"),
  (module) => module.ConfirmMail
);
