/**
 * Asynchronously loads the component for VRA
 */

import { lazyLoad } from "utils/loadable";

export const VRA = lazyLoad(
  () => import("./index"),
  (module) => module.VRA
);
