/**
 * Asynchronously loads the component for AddProperty
 */

import { lazyLoad } from "utils/loadable";

export const AddProperty = lazyLoad(
  () => import("./index"),
  (module) => module.AddProperty
);
