/**
 * Asynchronously loads the component for AA
 */

import { lazyLoad } from "utils/loadable";

export const AA = lazyLoad(
  () => import("./index"),
  (module) => module.AA
);
