/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Switch, Route, HashRouter } from "react-router-dom";

import { GlobalStyle } from "styles/global-styles";
import AppApolloprovider from "./ApolloProvider";
import { useTranslation } from "react-i18next";
import getStore from "store/zustandStore";
import { Backdrop, CircularProgress } from "@mui/material";

import { Home } from "./pages/HomePage/Loadable";
import { SetPassword } from "./pages/SetPasswordPage/Loadable";
import { SuperAdmin } from "./pages/SuperAdminPage/Loadable";
import { ManageUsers } from "./pages/ManageUsersPage/Loadable";
import { AddUser } from "./pages/AddUserPage/Loadable";
import { AddProperty } from "./pages/AddPropertyPage/Loadable";
import { ViewProperty } from "./pages/ViewPropertyPage/Loadable";
import { CustomerViewProperty } from "./pages/CustomerViewPropertyPage/Loadable";
import { VRA } from "./pages/VRAPage/Loadable";
import { DRA } from "./pages/DRAPage/Loadable";
import { AA } from "./pages/AAPage/Loadable";
import { QA } from "./pages/QAPage/Loadable";
import { Customer } from "./pages/CustomerPage/Loadable";
import { NotFoundPage } from "./components/NotFoundPage/Loadable";
import { Profile } from "./pages/ProfilePage/Loadable";
import { ConfirmMail } from "./pages/ConfirmMail/Loadable";
import { SuperAdminRemindersPage } from "./pages/SuperAdminRemindersPage/Loadable";
import { TermsAndConditions } from "./pages/TemrsAndConditionsPage/Loadable";
import ProtectedRoute from "./ProtectedRoute";

export function App() {
  const { i18n } = useTranslation();
  const loading = getStore((state: any) => state.loading);
  return (
    <HashRouter>
      <Helmet
        // titleTemplate="%s - React Boilerplate"
        defaultTitle="Realdocs"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Realdocs" />
      </Helmet>
      <AppApolloprovider>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/setpassword" component={SetPassword} />
          <Route exact path="/confirmemail" component={ConfirmMail} />
          <ProtectedRoute
            exact
            path="/super-admin"
            component={SuperAdmin}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/super-admin/property/:propertyId"
            component={ViewProperty}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/super-admin/profile/:userId"
            component={Profile}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/super-admin/manage-users"
            component={ManageUsers}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/super-admin/reminders"
            component={SuperAdminRemindersPage}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/super-admin/manage-users/add-edit-user/:userId"
            component={AddUser}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/add-edit-property/:propertyId"
            component={AddProperty}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/vra"
            component={VRA}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/vra/profile/:userId"
            component={Profile}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/vra/property/:propertyId"
            component={ViewProperty}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/dra"
            component={DRA}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/dra/profile/:userId"
            component={Profile}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/dra/property/:propertyId"
            component={ViewProperty}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/aa"
            component={AA}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/aa/profile/:userId"
            component={Profile}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/aa/property/:propertyId"
            component={ViewProperty}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/qa"
            component={QA}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/qa/profile/:userId"
            component={Profile}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/qa/property/:propertyId"
            component={ViewProperty}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/customer"
            component={Customer}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/customer/profile/:userId"
            component={Profile}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/customer/property/:propertyId"
            component={CustomerViewProperty}
            authenticationPath="/"
          />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          {/* Not Found Page */}
          <Route component={NotFoundPage} />
        </Switch>
      </AppApolloprovider>
      <GlobalStyle />
      <Backdrop
        open={loading || false}
        sx={{
          color: "#000000",
          opacity: "70%",
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
      >
        <CircularProgress />
      </Backdrop>
    </HashRouter>
  );
}
